import { connect as reduxConnector } from 'react-redux';
import { IHeaderState } from '../../reducers/header';
import { setUser, resetHeader } from '../../actions/header';
import { bindActionCreators, Dispatch } from 'redux';
import { push } from 'react-router-redux';


export interface IMapStateToProps {
    user?: IUser
}

export interface IMapDispatchToProps {
    setUser: (user: IUser) => void,
    resetHeader: () => void,
    redirect: (path: string) => void
}

export const mapStateToProps = (state: {header: IHeaderState}) => {
    const { user } = state.header;
    return { user }
}

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
    setUser: (user: IUser) => setUser(user),
    resetHeader: () => resetHeader(),
    redirect: (path: string) => push(path)
}, dispatch)

export const connector = reduxConnector<IMapStateToProps, IMapDispatchToProps>(mapStateToProps, mapDispatchToProps);

export const headerConenctor = reduxConnector<IMapStateToProps > (mapStateToProps);
