import * as React from "react";
import * as okta from "@okta/okta-react";
import { IMapStateToProps, IMapDispatchToProps, connector } from "./connector";
import { setAuthenticator } from "../../utils";
import { parseUser } from "../../utils/index";
import {
  AppBar,
  Button,
  Toolbar,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  withTheme
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

export interface IHeaderProps extends IMapStateToProps, IMapDispatchToProps {
  auth: Auth;
  title?: string;
}

export interface IHeaderState {
  anchorEl?: HTMLElement;
  readonly menuLoginOpen?: boolean;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
  public readonly state: IHeaderState = {};
  constructor(props: IHeaderProps, state: IHeaderState) {
    super(props, state);
    this.logIn = this.logIn.bind(this);
    this.logout = this.logout.bind(this);
    this.handleClose = this.handleClose.bind(this);
    setAuthenticator(this.props.auth);
  }
  public componentDidMount() {
    if (this.props.auth) {
      this.props.auth.getAccessToken().then(token => {
        const user = parseUser(token);
        if (user) {
          this.props.setUser(user);
        }
      });
    }
  }
  public componentDidUpdate(prevProps: IHeaderProps) {
    if (!prevProps.auth && this.props.auth) {
      setAuthenticator(this.props.auth);
      this.props.auth.getAccessToken().then(token => {
        const user = parseUser(token);
        if (user) {
          this.props.setUser(user);
        }
      });
    }
  }

  public render() {
    const { menuLoginOpen } = this.state;
    const { title, user, redirect } = this.props;
    const { username } = user || { username: "" };

    return (
      <AppBar position="static">
        <Toolbar>
          <Grid container={true} direction="row">
            <Button
              buttonRef={(node: HTMLElement) => {
                this.state.anchorEl = node;
              }}
              color="inherit"
              onClick={() => {
                redirect("/");
              }}
              id="home-btn"
            >
              {title}
            </Button>
            <Grid item={true} className="flex" />
            <Grid item={true} id="authblock">
              {!user ? (
                <Button color="inherit" onClick={this.logIn} id="login-btn">
                  <Typography color="inherit">Login</Typography>
                </Button>
              ) : (
                <Grid container={true}>
                  <Typography color="inherit">
                    {username}
                    <IconButton
                      aria-owns={menuLoginOpen ? "menu-login" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleToogleLoginMenu}
                      color="inherit"
                      buttonRef={(node: HTMLElement) => {
                        this.state.anchorEl = node;
                      }}
                    >
                      <AccountCircle />
                    </IconButton>
                  </Typography>
                  <Menu
                    id="menu-login"
                    open={menuLoginOpen ? true : false}
                    onClose={this.handleCloseLoginMenu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                  >
                    <MenuItem onClick={this.logout} id="logout-btn">
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
  private logIn = () => {
    const location = window.location.pathname;
    this.props.auth.login(location);
  };
  private logout = () => {
    this.props.resetHeader();
    this.props.auth.logout("/");

    this.handleClose();
  };
  private handleClose = () => {
    this.setState({ anchorEl: undefined });
  };
  private handleToogleLoginMenu = (event: any) => {
    event.stopPropagation();
    this.setState(state => ({ menuLoginOpen: !state.menuLoginOpen }));
  };
  private handleCloseLoginMenu = (event: any) => {
    event.stopPropagation();
    if (this.state.anchorEl && this.state.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ menuLoginOpen: false });
  };
}

export { Header };

export default connector(withTheme()(okta.withAuth(Header)));
