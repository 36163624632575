import { Actions } from '../actions/header';

export interface IHeaderState {
  user?: IUser;
}
export const initialState: IHeaderState = {
    user: undefined
}

interface IHeaderPayLoad {
    user: IUser
}
const reducer = (state: IHeaderState = initialState, action: Action<string, IHeaderPayLoad> ): IHeaderState => {
    switch (action.type) {
        case 'RESET':
        case Actions.RESET: {
            return initialState
        }
        case Actions.SET_USER: {
            const payload = (action as ActionWithPayload<string, IHeaderPayLoad>).payload
            const user = payload.user;
            const newState: IHeaderState = { ...state, user};
            return newState;
        }
        default:
        return state;
    }
}

export default reducer;
