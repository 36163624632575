import { createAction } from '../utils/index';

export const Actions = {
    RESET: "RESET_HEADER",
    SET_USER: "SET_USER",
}

export const setUser = (user: IUser) => createAction(
           Actions.SET_USER,
           { user }
         );
export const resetHeader = () => createAction(Actions.RESET);