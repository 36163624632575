import * as React from "react";
import { IMapStateToProps } from "../header/connector";
import modelService from "./service";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Badge
} from "@material-ui/core";
import VersionList from "../versions";
import { getAuthenticator } from "../../utils";
import { IMapDispatchToNotProps } from "../notificator/connector";
import { connector } from "./connector";
import { FFNNotification } from "../notificator/index";
import { info } from "verror";

export interface IModelsProps extends IMapStateToProps, IMapDispatchToNotProps {
  fetchSize?: number;
}
export interface IModelsState {
  list: Model[];
  selected?: string;
  showMoreBtn?: boolean;
  fetching?: boolean;
}

const defaultLimit = 50;

export class ModelList extends React.Component<IModelsProps, IModelsState> {
  public state: IModelsState = {
    list: []
  };

  private modelService: ModelService = modelService;
  constructor(props: IModelsProps, state: IModelsState) {
    super(props, state);
    this.fetchModels = this.fetchModels.bind(this);
    this.refreshModels = this.refreshModels.bind(this);
  }
  public componentDidMount() {
    if (this.props.user) {
      this.fetchModels();
    }
  }
  public componentDidUpdate(prevProps: IModelsProps) {
    if (prevProps.user !== this.props.user && this.props.user) {
      this.fetchModels();
    }
  }
  public render() {
    const { user } = this.props;
    const { list, selected, showMoreBtn, fetching } = this.state;
    if (!user) {
      return null;
    }

    return (
      <Grid
        item={true}
        container={true}
        direction={"row"}
        justify="space-around"
      >
        <Grid
          item={true}
          container={true}
          xs={4}
          direction="column"
          style={{height:600}}
        >
          <Typography variant="h6"> Models </Typography>
          <List className="col-block">
            {(list || []).map(model => (
              <ListItem
                key={model.name}
                id={`model-${model.name}`}
                button={true}
                selected={model.name === selected}
                onClick={this.selectModelHandler(model.name)}
              >
                <ListItemText
                  primary={
                    <Typography variant="h5" style={{ padding: 5, margin: 5 }}>
                      {model.name}
                    </Typography>
                  }
                  secondaryTypographyProps={{ component: "div" }}
                  secondary={
                    <Grid
                      container={true}
                      alignContent="space-around"
                      direction="row"
                    >
                      <Badge
                        color="primary"
                        badgeContent={model.total_versions}
                      >
                        <Typography
                          variant="caption"
                          style={{ paddingRight: 10, paddingLeft: 20 }}
                        >
                          #Versions
                        </Typography>
                      </Badge>
                      <Badge
                        color="primary"
                        badgeContent={model.running_versions}
                        classes={{ colorPrimary: "runningv" }}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            paddingRight: 10,
                            paddingLeft: 20
                          }}
                        >
                          Running
                        </Typography>
                      </Badge>
                    </Grid>
                  }
                />
              </ListItem>
            ))}
          </List>
          {showMoreBtn && (
            <Grid>
              <Button
                color="primary"
                disabled={fetching}
                onClick={this.fetchModels}
              >
                More ...
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item={true} xs={7} className="col-block">
          <VersionList model={selected} onUpdate={this.refreshModels} />
        </Grid>
      </Grid>
    );
  }

  private selectModelHandler = (selected: string) => () => {
    this.setState({ selected });
  };

  private fetchModels = () => {
    const list = this.state.list || [];
    const offset = list.length;
    const limit = this.props.fetchSize || defaultLimit;
    this.setState({ fetching: true });
    this.modelService
      .get({ limit, offset })
      .then(models => {
        const showMoreBtn = models.length === limit;
        this.setState({
          fetching: false,
          list: [...list, ...models],
          showMoreBtn
        });
      })
      .catch(err => {
        this.setState({ fetching: false });
        const errInfo = info(err);
        const level = errInfo.level || "error";
        const data: string[] = errInfo.data || [""];
        this.props.pushNotification(
          ...data.map(d => new FFNNotification(level, `${err.message}: ${d}`))
        );
        return [];
      });
  };
  private refreshModels = () => {
    const limit = this.props.fetchSize || defaultLimit;
    getAuthenticator()
      .then(auth => {
        return auth.isAuthenticated().then(logged => {
          if (!logged) {
            auth.login(window.location.pathname);
            return "";
          }
          return auth.getAccessToken();
        });
      })
      .then(token => {
        const list = this.state.list || [];

        this.setState({ fetching: true });
        const promises: Array<Promise<Model[]>> = [];
        let offset = 0;

        while (offset < list.length) {
          promises.push(
            this.modelService.get({ limit, offset, accessToken: token })
          );
          offset = offset + limit;
        }
        return Promise.all(promises);
      })
      .then(result => {
        const slist = (this.state.list || []).length;
        let newList: Model[] = [];
        newList = newList.concat(...result);
        const showMoreBtn =
          slist <= newList.length && newList.length % limit === 0;
        this.setState({
          fetching: false,
          list: newList,
          showMoreBtn
        });
      })
      .catch(err => {
        const errInfo = info(err);
        const level = errInfo.level || "error";
        const data: string[] = errInfo.data || [""];
        this.props.pushNotification(
          ...data.map(d => new FFNNotification(level, `${err.message}: ${d}`))
        );
        this.setState({
          fetching: false
        });
      });
  };
}

export default connector(ModelList);
