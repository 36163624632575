import * as okta from '@okta/okta-react';
import * as React from 'react';
import { Route, Switch } from 'react-router'
import * as config from '../config';
import AppRouted from './approuted';
import { HealthCheckComponent } from './healthcheck';

const Security = okta.Security;
const ImplicitCallback = okta.ImplicitCallback;
interface IAppState {
  readonly AUTH_ISSUER?: string
  readonly AUTH_CLIENT_ID?: string
  readonly AUTH_REDIRECT_URI?: string
  readonly APP_TITLE?: string
}
export class App extends React.Component<{}, IAppState> {
  public readonly state: IAppState = {

  }
  constructor(props: {}, state: IAppState) {
    super(props, state)
  }
  public componentDidMount() {
    config.ConfData.then(data=>{
      const { AUTH_ISSUER, AUTH_CLIENT_ID, AUTH_REDIRECT_URI, APP_TITLE } = data
      this.setState({
        AUTH_ISSUER,
        AUTH_CLIENT_ID,
        AUTH_REDIRECT_URI,
        APP_TITLE
      });
    })
  }

  public render(): React.ReactNode {
    const { AUTH_ISSUER, AUTH_CLIENT_ID, AUTH_REDIRECT_URI, APP_TITLE } = this.state;
    return <div>
      {AUTH_ISSUER && AUTH_CLIENT_ID && AUTH_REDIRECT_URI && <Security issuer={AUTH_ISSUER}
                  client_id={AUTH_CLIENT_ID}
                  redirect_uri={AUTH_REDIRECT_URI}

          >
          <Switch>
            <Route path="/health/check" component={HealthCheckComponent} />
            <Route path="/health/readiness" component={HealthCheckComponent} />
            <Route path='/implicit/callback' component={ImplicitCallback}/>
            <AppRouted title={APP_TITLE}/>
          </Switch>
        </Security>}
    </div>
  }
}

export default App;
