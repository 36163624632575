import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
require("../../setupTest");
export interface IConfirmProps {
  openConfirmation?: boolean;
  action?: () => Promise<void>;
  title?: string;
  message?: string;
  onClose: () => void;
}

export interface IConfirmState {
  readonly open: boolean;
}

export class ConfirmationCompt extends React.Component<
  IConfirmProps,
  IConfirmState
> {
  public readonly state: IConfirmState = {
      open: false
  }
  public constructor(props: IConfirmProps, state: IConfirmState) {
    super(props, state);
    this.closeConfirmHandler = this.closeConfirmHandler.bind(this);
  }

  public componentDidMount() {
    this.setState({ open: this.props.openConfirmation || false });
  }
  public componentDidUpdate(
    prevProps: IConfirmProps,
    prevState: IConfirmState
  ) {
    if (!!this.props.openConfirmation !== this.state.open) {
      this.setState({ open: this.props.openConfirmation || false });
    }
    this.closeConfirmHandler = this.closeConfirmHandler.bind(this)
    this.actionHandler = this.actionHandler.bind(this)
  }

  public render() {
    
    const {open} = this.state
    let { title, message } = this.props;
    title = title || "Confirm Action";
    message = message || "Do you really want to execute the action?";

    return <Dialog open={open} onClose={this.closeConfirmHandler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeConfirmHandler} id="no-confirm">NO</Button>
          <Button onClick={this.actionHandler} color="primary" id="confirm">YES</Button>
        </DialogActions>
      </Dialog>;
  }
  private closeConfirmHandler = () => {
      
      // this.setState({ open: false });
      this.props.onClose();
  }
  private actionHandler = () => {
      if (this.props.action) {
        this.props.action().then(this.closeConfirmHandler).catch(() => null)
      } else {
        this.closeConfirmHandler();
      }
  }
}
