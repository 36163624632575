import { AuthenticateFuncWOptions, AuthenticateFunc } from "../../utils/index";
import axios, { AxiosRequestConfig } from "axios";
import { WError } from "verror";
import { ConfData } from "../../config"

export class VersionService implements VersionService {
    private baseUri: string
    constructor(baseUri?: string) {
        if (baseUri) {
            this.baseUri = baseUri;
        } else {
            ConfData.then(c => {
                this.baseUri = c.ROUTER_BASE_URI;
            })
        }
    }
    @AuthenticateFuncWOptions(2)
    public async get(
        model: string,
        options: IAuthenticateFuncOptions & { offset?: number; limit?: number }
    ): Promise<Version[]> {
        if (!this.baseUri) {
            this.baseUri = (await ConfData).ROUTER_BASE_URI;
        }
        return new Promise<Version[]>((resolve, reject) => {
            const config: AxiosRequestConfig = {};
            config.headers = { "Content-Type": "application/json" };
            if (options.accessToken) {
                config.headers.Authorization = `Bearer ${options.accessToken}`;
            }
            options.limit = Math.min(options.limit || 10, 50);
            options.offset = options.offset || 0;
            config.params = {};
            config.params.offset = options.offset;
            config.params.limit = options.limit;
            axios
                .get(`${this.baseUri}/v1/models/${model}/versions`, config)
                .then(response => {
                    if (response.status !== 200) {
                        const err = new WError({
                            name: "ServiceError",
                            info: {
                                level: "error",
                                data: response.data
                            }
                        }, response.statusText);
                        reject(err);
                    } else {
                        const list: Version[] = response.data || [];
                        resolve(list);
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 404) {
                        resolve([]);
                        return;
                    }
                    let e: Error;
                    switch (err.response.status) {
                        case 400: {
                            e = new WError({
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: [err.response.data.invalid_parameters]
                                }
                            }, "Invalid Parameters");
                            break;
                        }
                        case 401: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "warning",
                                        data: ["Session expired"]
                                    },
                                    cause: err
                                },
                                "Invalid Session"
                            );
                            break;
                        }
                        case 500: {
                            e = new WError({
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: [
                                        err.response.data.internal_server_error || err.response.data
                                    ]
                                }
                            }, "Internal Server Error");
                            break;
                        }
                        default: {
                            e = new WError({
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: [`${err.response.statusText} -> ${err.response.data}`]
                                }
                            }, "Error");
                        }
                    }
                    reject(e);
                });
        });
    }
    @AuthenticateFunc(4)
    public async updateWeight(
        model: string,
        version: string,
        weight: number,
        token?: string
    ): Promise<Version> {
        if (!this.baseUri) {
            this.baseUri = (await ConfData).ROUTER_BASE_URI;
        }
        return new Promise<Version>((resolve, reject) => {
            const config: AxiosRequestConfig = {};
            config.headers = { "Content-Type": "application/json" };
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            axios
                .patch(
                    `${this.baseUri}/v1/models/${model}/versions/${version}`,
                    { name: version, weight },
                    config
                )
                .then(response => {
                    if (response.status !== 200) {
                        const err = new WError({
                            name: "ServiceError",
                            info: {
                                data: response.data
                            }
                        }, response.statusText);
                        reject(err);
                    } else {
                        const version: Version = response.data;
                        resolve(version);
                    }
                })
                .catch(err => {
                    let e: Error;
                    switch (err.response.status) {
                        case 400: {
                            const messages = Object.keys(
                                err.response.data.invalid_parameters
                            ).map(k => {
                                return `${k} -> ${err.response.data.invalid_parameters[k]} `;
                            });
                            e = new WError({
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: messages
                                }
                            }, "Invalid Parameter");
                            break;
                        }
                        case 401: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "warning",
                                        data: ["Session expired"]
                                    },
                                    cause: err
                                },
                                "Invalid Session"
                            );
                            break;
                        }
                        case 404: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "warning",
                                        data: [err.response.data.version]
                                    },
                                    cause: err
                                },
                                "Missing"
                            );
                            break;
                        }
                        case 500: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "error",
                                        data: [
                                            err.response.data.internal_server_error ||
                                            err.response.data
                                        ]
                                    },
                                    cause: err
                                },
                                "Internal Server Error"
                            );
                            break;
                        }
                        default: {
                            e = new WError({
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: [`${err.response.statusText} -> ${err.response.data}`]
                                }
                            }, "Error");
                        }
                    }
                    reject(e);
                });
        });
    }
    @AuthenticateFunc(3)
    public async deleteVersion(model: string, version:string, token?: string):Promise<void> {
        if(!this.baseUri) {
            this.baseUri = (await ConfData).ROUTER_BASE_URI;
        }
        return new Promise<void>((resolve, reject) => {
            const config: AxiosRequestConfig = {};
            config.headers = {
                "Content-Type": "application/json"
            };
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            axios
              .delete(`${this.baseUri}/v1/models/${model}/versions/${version}`, config)
              .then((response => {
                if (response.status !== 204) {
                    const err = new WError({
                        name: "ServiceError",
                        info: {
                            level: "error",
                            data: response.data
                        }
                    }, response.statusText);
                    reject(err);
                }
                resolve()
            })).catch(err => {
                let e: Error;
                switch ((err.response || {status: 0}).status) {
                    case 400: {
                        const messages = Object.keys(
                            err.response.data.invalid_parameters
                        ).map(k => {
                            return `${k} -> ${err.response.data.invalid_parameters[k]} `;
                        });
                        e = new WError({
                            name: "ServiceError",
                            info: {
                                level: "error",
                                data: messages
                            }
                        }, "Invalid Parameter");
                        break;
                    }
                    case 401: {
                        e = new WError(
                            {
                                name: "ServiceError",
                                info: {
                                    level: "warning",
                                    data: ["Session expired"]
                                },
                                cause: err
                            },
                            "Invalid Session"
                        );
                        break;
                    }
                    case 404: {
                        e = new WError(
                            {
                                name: "ServiceError",
                                info: {
                                    level: "warning",
                                    data: [err.response.data.version]
                                },
                                cause: err
                            },
                            "Missing"
                        );
                        break;
                    }
                    case 500: {
                        e = new WError(
                            {
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: [
                                        err.response.data.internal_server_error ||
                                        err.response.data
                                    ]
                                },
                                cause: err
                            },
                            "Internal Server Error"
                        );
                        break;
                    }
                    default: {
                        e = new WError({
                            name: "ServiceError",
                            info: {
                                level: "error",
                                data: [err.response ? `${err.response.statusText} -> ${err.response.data}`: `Unexpected Error -> ${err}`]
                            }
                        }, "Error");
                    }
                }
                reject(e);
            });
        })
    } 
}

export default new VersionService();
