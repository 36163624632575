import { connect as reduxConnector } from "react-redux";

import { mapDispatchToNotProps } from '../notificator/connector';
import { mapStateToProps as mapHeaderStateToProps} from "../header/connector";


export const connector = reduxConnector(mapHeaderStateToProps, mapDispatchToNotProps);
/*
import { connect as reduxConnector } from "react-redux";
import { IHeaderState } from "../../reducers/header"
import { mapDispatchToNotProps, IMapStateToProps, IMapDispatchToNotProps } from '../notificator/connector';
import { IModelsState } from '../../reducers/models';
import { bindActionCreators, Dispatch } from 'redux';
import { setModels } from '../../actions/models';
import {
  mapStateToProps as mapHeaderStateToProps,
  IMapStateToProps as IHeaderState2Props
} from "../header/connector";

export interface IMapStateToProps extends IHeaderState2Props {
    models: Model[]
}

export const mapStateToProps = (state: { header: IHeaderState, models: IModelsState }) => {
    const { user } = state.header;
    const models = Object.keys(state.models || []).sort().map(k => state.models[k])
    return { user, models }
}

export interface IMapDispatchToProps extends IMapDispatchToNotProps {
  setModels: (models: Model[]) => void;
}

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setModels: (models:Model[]) => setModels(models)
}, dispatch)

export const connector = reduxConnector(mapStateToProps, mapDispatchToProps);

export const basicConnector = reduxConnector(mapHeaderStateToProps, mapDispatchToNotProps);
*/