import {Actions} from '../actions/notification';

export interface INotificationState {
    readonly notifications: AppNotification[];
}

export const initialState: INotificationState = {
    notifications: []
}

interface INotificationPlayLoad {
    notifications?: AppNotification[],
    id?: number
}

const reducer = (state: INotificationState = initialState, action: ActionWithPayload<string, INotificationPlayLoad>): INotificationState => {
    switch (action.type) {
        case Actions.PUSH: {
            if (!action.payload.notifications){
                return state
            }
            const oldNotifications = state.notifications
            const notifications = [...oldNotifications, ...action.payload.notifications];
            return {...state, notifications}
        }
        case Actions.DELETE: {
            if (!action.payload.id) {
                return state
            }
            const notifications = state.notifications.filter(not => not.id !== action.payload.id);
            return { ...state, notifications }
        }
        default: {
            return state
        }
    }
}

export default reducer