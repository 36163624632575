import * as React from "react";
import { IMapStateToProps, IMapDispatchToProps } from "./connector";
import { Snackbar, SnackbarContent, Grid, IconButton, Slide } from "@material-ui/core";
import { connector } from "./connector";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from "@material-ui/icons";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

export interface INotificatorProps
  extends IMapStateToProps,
    IMapDispatchToProps {
  delay?: number;
}
export interface INotificatorState {
  notMap: { [key: number]: boolean };
}
const transition = (props:any) => (<Slide {...props} direction="right"/>)
export class Notificator extends React.Component<
  INotificatorProps,
  INotificatorState
> {
  public readonly state: INotificatorState = {
    notMap: {}
  };
  constructor(props: INotificatorProps, state: INotificatorState) {
    super(props, state);
    this.closeNotificationHandler = this.closeNotificationHandler.bind(this);
  }

  public componentDidMount() {
    const notifications = {};
    this.props.notifications.forEach(not => {
      notifications[not.id] = true;
    });
    this.setState(notifications);
  }
  public componentDidUpdate(prevProps: INotificatorProps) {
    const { notMap } = this.state;
    let newNot = false;
    this.props.notifications.forEach(not => {
      if (notMap[not.id] === undefined) {
        notMap[not.id] = true;
        newNot = true;
      }
    });

    if (newNot) {
      this.setState({ notMap });
    }
  }
  public render() {
    const { delay, notifications } = this.props;
    const { notMap } = this.state;
    const t2close = (delay || 5) * 1000;
    return (
      <Grid
        container={true}
        style={{ bottom: 0 }}
        direction="column-reverse"
      >
        {notifications.map(not => {
          const Icon = variantIcon[not.level];
          return (
            <Snackbar
              key={not.id}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              className="notification"
              open={notMap[not.id]}
              autoHideDuration={t2close}
              onClose={this.closeNotificationHandler(not.id)}
              TransitionComponent={transition}
            >
              <SnackbarContent
                className={`not_${not.level}`}
                aria-describedby={`not-${not.id}`}
                message={
                  <span
                    id={`not-${not.id}`}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <Icon className="not_icon not_icon_variant" /> {not.message}
                  </span>
                }
                action={[
                  <IconButton
                    key={`close-${not.id}`}
                    id={`close-${not.id}`}
                    aria-label={`Close${not.id}`}
                    color="inherit"
                    className="not_close"
                    onClick={this.closeNotificationHandler(not.id)}
                  >
                    <CloseIcon className="not_icon" />
                  </IconButton>
                ]}
              />
            </Snackbar>
          );
        })}
      </Grid>
    );
  }

  private closeNotificationHandler = (id: number) => (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    const { notMap } = this.state;
    notMap[id] = false;
    this.setState({ notMap });
    // this.props.removeNotification(id);
  };
}

export default connector(Notificator);
