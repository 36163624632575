import { Component, FormEvent } from "react";
import { WError } from 'verror';

export function createAction<T extends string, P>(
  type: T,
  payload?: P
): Action<T> | ActionWithPayload<T, P> {
  return payload === undefined ? { type } : { type, payload };
}

export function changeHandler(i: Component): ChangeHandler {
  const handler = (f: string) => (e: FormEvent<HTMLDataElement>) => {
    const obj = {};
    obj[f] = e.currentTarget.value;
    i.setState(obj);
  };
  return handler;
}

export function toogleCheck(i: Component) {
  const handler = (f: string) => (event: React.FormEvent<HTMLInputElement>) => {
    const obj = {};
    obj[f] = event.currentTarget.checked;
    i.setState(obj);
  };
  return handler;
}

export const parseUser = (token?: string): IUser | undefined => {
  if (!token) {
    return;
  }
  const userData = JSON.parse(atob(token.split(".")[1]));
  const user = {
    business_ids: userData.bsn,
    username: userData.sub,
    roles: userData.roles
  };
  return user;
};

let authI: Auth;
/**
 * Set the authenticator if this hasn't set yet
 * @param auth
 */
function setAuthenticator(auth: Auth) {
  if (!authI) {
    authI = auth;
  }
}

/**
 * Returns the authenticator setted
 */
function getAuthenticator(): Promise<Auth> {
  if (authI !== undefined) {
    return Promise.resolve(authI);
  } else {
    return Promise.reject(new Error("authenticator hasn't been set"));
  }
}

const AuthenticateFunc = (nArgs: number, auth?: Auth) => (
  target: any,
  propertyKey: string | symbol,
  descriptor: PropertyDescriptor
) => {
  const method = descriptor.value;
  if (!method) {
    return;
  }
  descriptor.value = (...args: any[]) => {
    if (
      nArgs === 0 ||
      (args.length >= nArgs && args[nArgs - 1] !== undefined)
    ) {
      const result = method.apply(target);
      if (result.then) {
        return result;
      }
      return Promise.resolve(result);
    }
    return getAuthenticator().then(auth => {
      return auth
        .isAuthenticated()
        .then((logged: boolean) => {
          if (!logged) {
            const e = new WError(
              {
                name: "ServiceError",
                info: {
                  level: "warning",
                  data: ["Session expired"]
                },
              },
              "Invalid Session"
            );
            return Promise.reject(e);
          }
          return auth.getAccessToken();
          // Handle auth errors before
        })
        .then((token?: string) => {
          return method.call(target, ...args, token);
        });
    });
  };
};

const AuthenticateFuncWOptions = (nArgs: number, auth?: Auth) => (
  target: any,
  propertyKey: string | symbol,
  descriptor: PropertyDescriptor
) => {
  const method = descriptor.value;
  if (!method) {
    return;
  }
  descriptor.value = (...args: Array<any | IAuthenticateFuncOptions>) => {
    const opts: IAuthenticateFuncOptions = typeof args[
      nArgs - 1
    ] as IAuthenticateFuncOptions;
    if (opts !== undefined && opts.accessToken !== undefined) {
      const result = method.call(target, ...args);
      if (result.then) {
        return result;
      }
      return Promise.resolve(result);
    }
    if (opts === undefined) {
      args[nArgs - 1] = {
        accessToken: undefined
      };
    }

    return getAuthenticator().then(auth => {
      return auth
        .isAuthenticated()
        .then((logged: boolean) => {
          if (!logged) {
            auth.login(window.location.pathname);
            return Promise.reject(new Error("not authenticate"));
          }
          return auth.getAccessToken();
          // Handle auth errors before
        })
        .then((token?: string) => {
          args[nArgs - 1].accessToken = token;
          return method.call(target, ...args);
        });
    });
  };
};

export {
  setAuthenticator,
  getAuthenticator,
  AuthenticateFunc,
  AuthenticateFuncWOptions
};
