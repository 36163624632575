interface IConfig {
    readonly AUTH_ISSUER: string
    readonly AUTH_REDIRECT_URI: string
    readonly AUTH_CLIENT_ID: string
    readonly APP_TITLE: string
    readonly ROUTER_BASE_URI: string
}
const confData = fetch(`${process.env.PUBLIC_URL}/config/config.json`)
    .then(r => {
        return r.json();
    })
    .then(json => {
        const config: IConfig = {
            APP_TITLE: json.appTitle || "FFN Authentication",
            AUTH_CLIENT_ID: json.oktaClientId,
            AUTH_ISSUER: json.oktaIssuer,
            AUTH_REDIRECT_URI: window.location.origin + "/implicit/callback",
            ROUTER_BASE_URI: json.routerBaseURI,
        };
        return config
    }).catch((err) => {
        alert(err)
        throw err
    });

export { confData as ConfData }

export const ROLE_ADMIN = "ffn-fuse-router.editor";
export const ROLE_USER = "fn-fuse-router.user";
export const ROLE_MAP = {
    "ffn-fuse-router.editor": true,
    "fn-fuse-router.user": true
};
