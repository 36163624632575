import axios, { AxiosRequestConfig } from "axios";
import { WError } from "verror";
import { AuthenticateFuncWOptions } from "../../utils/index";
import { ConfData } from "../../config"

export class ModelService implements ModelService {
    public baseUri: string

    @AuthenticateFuncWOptions(1)
    public async get(
        options: IAuthenticateFuncOptions & { offset?: number; limit?: number }
    ): Promise<Model[]> {
        if (!this.baseUri) {
            this.baseUri = (await ConfData).ROUTER_BASE_URI;
        }
        return new Promise<Model[]>((resolve, reject) => {
            const config: AxiosRequestConfig = {};
            config.headers = { "Content-Type": "application/json" };
            if (options.accessToken) {
                config.headers.Authorization = `Bearer ${options.accessToken}`;
            }
            options.limit = Math.min(options.limit || 10, 50);
            options.offset = options.offset || 0;
            config.params = {};
            config.params.offset = options.offset;
            config.params.limit = options.limit;

            axios
                .get(`${this.baseUri}/v1/models`, config)
                .then(response => {
                    if (response.status !== 200 && response.status !== 404) {
                        const err = new WError(
                            {
                                name: "ServiceError",
                                info: {
                                    level: "error",
                                    data: response.data
                                },
                                cause: new Error("Unexpected statusCode")
                            },
                            response.statusText
                        );
                        reject(err);
                    } else {
                        const list: Model[] = response.status === 200 ? response.data : [];
                        resolve(list);
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 404) {
                        resolve([]);
                        return;
                    }
                    let e: Error;
                    switch (err.response.status) {
                        case 400: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "error",
                                        data: [err.response.data.invalid_parameters]
                                    },
                                    cause: err
                                },
                                "Invalid Parameters"
                            );
                            break;
                        }
                        case 401: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "warning",
                                        data: ["Session expired"]
                                    },
                                    cause: err
                                },
                                "Invalid Session"
                            );
                            break;
                        }
                        case 500: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    cause: err,
                                    info: {
                                        level: "error",
                                        data: [
                                            err.response.data.internal_server_error ||
                                            err.response.data
                                        ]
                                    }
                                },
                                "Internal Server Error"
                            );
                            break;
                        }
                        default: {
                            e = new WError(
                                {
                                    name: "ServiceError",
                                    info: {
                                        level: "error",
                                        data: [`${err.response.statusText} -> ${err.response.data}`]
                                    },
                                    cause: err
                                },
                                "Internal Server Error"
                            );
                        }
                    }
                    reject(e);
                });
        });
    }
}

export default new ModelService();
