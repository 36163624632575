import { History } from 'history';
import { routerMiddleware, routerReducer } from "react-router-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import header, { initialState as HeaderState } from './header';
import notifications, { initialState as NotificationsState } from './notification';
export { HeaderState, NotificationsState }
export default (history: History) =>
  createStore(
    combineReducers({
      header,
      notifications,
      routerReducer
    }),
    applyMiddleware(routerMiddleware(history))
  );