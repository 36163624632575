import * as React from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  IconButton
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { info } from "verror";
import versionService from "./service";
import { ConfirmationCompt } from "../confirm";
import {
  notificationConnector,
  IMapDispatchToNotProps
} from "../notificator/connector";
import { FFNNotification } from "../notificator/index";
import "./index.css";
export interface IVersionProps extends IMapDispatchToNotProps {
  fetchSize?: number;
  model?: string;
  onUpdate?: () => void;
}
export interface IVersionState {
  list: Version[];
  originalW: {
    [key: string]: {
      original: number;
      current: number;
      index: number;
    };
  };
  totalW: number;
  modalOpen?: boolean;
  confirmMsg?: string;
  action2Confirm?: () => Promise<void>;
}
export class VersionList extends React.Component<IVersionProps, IVersionState> {
  public state: IVersionState = { list: [], originalW: {}, totalW: 0 };
  private versionService: VersionService = versionService;
  constructor(props: IVersionProps, state: IVersionState) {
    super(props, state);
    this.changeWeightHandler = this.changeWeightHandler.bind(this);
    this.fetchVersions = this.fetchVersions.bind(this);
    this.deleteVersionFGen = this.deleteVersionFGen.bind(this);
  }
  public componentDidMount() {
    if (this.props.model && this.props.model !== "") {
      this.fetchVersions().then(list => {
        const originalW = {};
        let totalW = 0;
        list.forEach((v, i) => {
          originalW[v.name] = {
            original: v.weight,
            current: v.weight,
            index: i
          };
          totalW = totalW + v.weight;
        });
        this.setState({ list, originalW, totalW });
      });
    }
  }
  public componentDidUpdate(prevProps: IVersionProps) {
    if (
      prevProps.model !== this.props.model &&
      this.props.model &&
      this.props.model !== ""
    ) {
      this.fetchVersions().then(list => {
        const originalW = {};
        let totalW = 0;
        list.forEach((v, i) => {
          originalW[v.name] = {
            original: v.weight,
            current: v.weight,
            index: i
          };
          totalW = totalW + v.weight;
        });
        this.setState({ list, originalW, totalW });
      });
    } else if (
      (prevProps.model !== this.props.model && !this.props.model) ||
      this.props.model === ""
    ) {
      this.setState({ list: [], originalW: {} });
    }
  }
  public render() {
    const {
      list,
      originalW,
      totalW,
      modalOpen,
      confirmMsg,
      action2Confirm
    } = this.state;
    const edited = Object.keys(originalW)
      .map(k => originalW[k])
      .some(v => v.original !== v.current);
    return <Grid container={true} direction="column" alignItems="center" xs={12}>
        <ConfirmationCompt message={confirmMsg} openConfirmation={modalOpen} onClose={this.closeModal} action={action2Confirm} />
        <Grid item={true} xs={12}>
          <Typography variant="h6">Versions</Typography>
        </Grid>
        <form className="version-list">
          <Grid item={true} container={true} xs={12} direction="column">
            {(list || []).map((v, index) => (
              <Grid
                item={true}
                key={v.id}
                className={
                  originalW[v.name].original !== v.weight ? "modified" : ""
                }
                spacing={8}
                direction="row"
              >
                <TextField
                  label="Version"
                  className="version name"
                  InputProps={{ readOnly: true }}
                  margin="normal"
                  value={v.name}
                />
                <TextField
                  label="Weight"
                  className="version num"
                  margin="normal"
                  type="number"
                  value={v.weight}
                  onChange={this.changeWeightHandler(index)}
                />
                <TextField
                  label="Trafic"
                  className="version num"
                  InputProps={{ readOnly: true }}
                  margin="normal"
                  value={`${
                    totalW
                      ? Math.round((10000 * v.weight) / totalW) / 100
                      : 0
                  } %`}
                />
                <IconButton
                  className="del-version-btn"
                  disabled={originalW[v.name].original > 0}
                >
                  <DeleteIcon
                    color={
                      originalW[v.name].original > 0 ? "default" : "error"
                    }
                    onClick={this.confirmAction(
                      `Do you really want to delete version "${v.name}"?`,
                      this.deleteVersionFGen(v.model, v.name)
                    )}
                  />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </form>
        <Grid container={true} item={true} alignContent="flex-end" alignItems="flex-end">
          <div className="flex" />
          {list && list.length > 0 && <Button color="primary" disabled={!edited} onClick={this.confirmAction("Do you really want to update the weights?", this.updateWeights)}>
              save
            </Button>}
        </Grid>
      </Grid>;
  }
  private confirmAction = (
    confirmMsg: string,
    action2Confirm: () => Promise<void>
  ) => () => {
    // Test
    this.setState({
      confirmMsg,
      action2Confirm,
      modalOpen: true
    });
  };
  private changeWeightHandler = (index: number) => (
    e: React.FormEvent<HTMLDataElement>
  ) => {
    e.stopPropagation();
    const nString = (e.currentTarget.value || "").trim().replace(/^0*/, "");
    e.currentTarget.value = nString;
    const newW = nString !== "" ? parseInt(nString, 10) : 0;
    if (newW < 0) {
      return;
    }
    const { list, originalW } = this.state;
    const prev = originalW[list[index].name]
      ? originalW[list[index].name].current
      : undefined;
    if (prev === undefined) {
      return;
    }
    let { totalW } = this.state;
    list[index].weight = newW;
    totalW = totalW - prev + newW;
    originalW[list[index].name].current = newW;
    this.setState({ list, originalW, totalW });
  };
  private closeModal = () => {
    this.setState({ modalOpen: false });
  };
  //  private openModal = () => {
  //    this.setState({ modalOpen: true });
  //  };
  private updateWeights = (): Promise<void> => {
    const { originalW } = this.state;
    const { model, onUpdate } = this.props;
    if (!model || model === "") {
      return Promise.resolve();
    }
    const newWeights: Array<{ name: string; weight: number }> = [];
    Object.keys(originalW).forEach(k => {
      if (originalW[k].original !== originalW[k].current) {
        newWeights.push({
          name: k,
          weight: originalW[k].current
        });
      }
    });
    const promises = newWeights.map(w => {
      return this.versionService
        .updateWeight(model, w.name, w.weight)
        .then(version => {
          originalW[version.name].original = version.weight;
          this.props.pushNotification(
            new FFNNotification("success", `${version.name} updated`)
          );
          this.setState({ originalW });
        })
        .catch(err => {
          const errInfo = info(err);
          const level = errInfo.level || "error";
          const data: string[] = errInfo.data || [""];
          this.props.pushNotification(
            ...data.map(d => new FFNNotification(level, `${err.message}: ${d}`))
          );
        });
    });
    return Promise.all(promises).then(() => {
      // Update model list
      if (onUpdate){
        onUpdate()
      };
    });
  };

  private async fetchVersions(): Promise<Version[]> {
    const model = this.props.model;
    if (!model || model === "") {
      return [];
    }
    const limit = this.props.fetchSize || 50;
    const list: Version[] = [];
    try {
      let data = await this.versionService.get(model || "", {
        limit,
        offset: 0
      });
      list.push(...data);
      while (data.length === limit) {
        data = await this.versionService.get(model || "", {
          limit,
          offset: list.length
        });
        list.push(...data);
      }
    } catch (err) {
      const errInfo = info(err);
      const level = errInfo.level || "error";
      const data: string[] = errInfo.data || [""];
      this.props.pushNotification(
        ...data.map(d => new FFNNotification(level, `${err.message}: ${d}`))
      );
      return [];
    }
    return list;
  }

  private deleteVersionFGen = (model: string, version: string) => (): Promise<
    void
  > => {
    return this.versionService.deleteVersion(model, version).then(()=>{
      const {originalW, list} = this.state
      const { onUpdate } = this.props
      list.splice(originalW[version].index,1)
      const i = originalW[version].index
      delete originalW[version]
      for (const ov in originalW) {
        if (originalW[ov].index > i ) {
          originalW[ov].index--
        }
      }
      this.setState({list, originalW})
      if (onUpdate) {
        onUpdate()
      };
    }).catch(err => {
      const errInfo = info(err);
      const level = errInfo.level || "error";
      const data: string[] = errInfo.data || [""];
      this.props.pushNotification(
        ...data.map(d => new FFNNotification(level, `${err.message}: ${d}`))
      );
    });
  };
}

export default notificationConnector(VersionList);
