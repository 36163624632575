import { connect as reduxConnector } from "react-redux";
import {
  pushNotification,
  removeNotification
} from "../../actions/notification";
import { bindActionCreators, Dispatch } from "redux";
import { INotificationState } from "../../reducers/notification";

export interface IMapStateToProps {
  notifications: AppNotification[];
}

export interface IMapDispatchToProps {
  removeNotification: (index: number) => void;
}
export interface IMapDispatchToNotProps {
  pushNotification: (...nots: AppNotification[]) => void;
}

export const mapStateToProps = (state: {
  notifications: INotificationState;
}): IMapStateToProps => {
  const { notifications } = state.notifications;
  return { notifications };
};

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { removeNotification: (id: number) => removeNotification(id) },
    dispatch
  );

export const mapDispatchToNotProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      pushNotification: (...nots: AppNotification[]) =>
        pushNotification(...nots)
    },
    dispatch
  );
export const connector = reduxConnector<IMapStateToProps, IMapDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
);
const state = {};
export const notificationConnector = reduxConnector<{}, IMapDispatchToNotProps>(
  () => state,
  mapDispatchToNotProps
);
