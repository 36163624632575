import { createAction } from '../utils/index';

export const Actions = {
    PUSH: "PUSH_NOTIFICATION",
    DELETE: "DEL_NOTIFICATION"
}

export const pushNotification = (...nots: AppNotification[]) => createAction(Actions.PUSH, {notifications: nots})
export const removeNotification = (id: number) => createAction(
           Actions.DELETE,
           { id }
         );