import ModelList from "../components/models/index";

export interface IAppRoute {
  id: string;
  link: string;
  component: React.ComponentClass;
  roles: "*" | string[];
  exact?: boolean;
}
const router: IAppRoute[] = [
  {
    component: ModelList,
    exact: true,
    id: "model-list",
    link: "/",
    roles: "*"
  },
];

export default router;
