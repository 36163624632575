import * as React from 'react';
import { Route, Switch } from 'react-router'
// import AppHome from '../components/home'
import Header from '../components/header';
import router, {IAppRoute} from '../router';
// import {IMapStateToAuthProps, connect} from './header/container';
import Notificator from "./notificator";

interface IAppRouted {
  readonly title?: string
}
// export class AppRouted extends React.Component<IMapStateToAuthProps, {}> {
export class AppRouted extends React.Component<IAppRouted, {}> {
  // constructor(props: IMapStateToAuthProps, state:{}) {
  //   super(props, state)
  // }
  constructor(props: IAppRouted, state: {}) {
    super(props, state);
  }

  public render(): React.ReactNode {
    // const { user } = this.props;
    return <div className="main-frame">
        <Header {...this.props} />
        <main>
          <Switch>
            {/* <Route exact={true} path="/" component={AppHome}/> */}
            {// user &&
            // user.roles !== undefined &&
            router.map((link: IAppRoute) => (
              <Route
                exact={link.exact}
                path={link.link}
                component={link.component}
                key={"content-" + link.id}
              />
            ))}
          </Switch>

          <Notificator />
        </main>
      </div>;
  }
}

// export default connect(AppRouted);
export default AppRouted;
