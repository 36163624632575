import NotificatorC from "./component";



function* genId() {
    let id = 1;
    while (true) {
        yield id++
    }
}

const idGenerator = genId()

export class FFNNotification implements AppNotification {
    public level: NotificationLevel;
    public message: string;
    public id: number;
    constructor(level: NotificationLevel, message: string) {
        this.level = level;
        this.message = message
        this.id = idGenerator.next().value;
    };
}

export default NotificatorC