import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory, History } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "typeface-roboto";
import App from "./components/app";
import "./index.css";
import rootReducer from "./reducers";
import registerServiceWorker from "./registerServiceWorker";

window["__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__"] = true;

const history: History = createBrowserHistory();
const store = rootReducer(history);
const theme = createMuiTheme();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

registerServiceWorker();
